module.exports = {
    activeOrgs: [
        156, // APPLIED
        768, // Nod
        761, // APPLIED DEV
        544, // Homes England
        822, // Harper Collins
        880, // myPlanet
        887, // Wonka Industries (Bradley)
        1042, // Cognition Company
        1264, // Eve Sleep
        772, // England Wales cricket board
        1375, // earwig
        1483, // Usborne publishing
        1503, // Full frame initiative
        706, // Comic Relief
        1549, // Citizen UK
        1574, // Oak National Academy
        1630, // International Longevity Centre
        1573, // IWDA
        1635, // Climate Outreach
        1649, // PSS,
        1613, // Satellite Applications Catapult
        1703, // Historic England
        1723, // Kinship
        1730, // Charity bank
        1746, // OIAHE
        1759, // Hays 
        1767, // Oak National Academy Freelancers
        1771, // Congressional Progressive Caucus Center
        1774, // Pilotlight
        1776, // Opportunity Green
        1777, // Aquila Earth
        1781, // Virescent Ventures
        1784, // Art fund
        1785, // ISPA
        1787, // Keep Britain Tidy
        1788, // Marvel FMCG
        1166, // Health Data Research UK
        1793, // Founder's Pledge
        1645, // Brandeis School of San Francisco
        1794, // SPIN
        1796, // Innovation Unit
        1797, // Flexischools
        1800, // Arcadia Trust
        1801, // Southmead Development Trust
        1803, // Little Moons
        1804, // The Climate Coalition
        1639, // Renaisi
        1806, // Refuge
        1807, // The Good Food institute
        548,  // Apolitical
        // Adding a new Microsite Org - remember to go add it to lib/config.js
],
    perks: {
        flexible_hours: {
            name: 'Flexible working hours',
            icon: 'fas fa-user-clock',
        },
        work_from_home: {
            name: 'Work from home option',
            icon: 'fas fa-home',
        },
        healthcare: {
            name: 'Healthcare',
            icon: 'fas fa-medkit',
        },
        retirement: {
            name: 'Retirement benefits',
            icon: 'fas fa-piggy-bank',
        },
        life_insurance: {
            name: 'Life Insurance',
            icon: 'fas fa-heartbeat',
        },
        wellness: {
            name: 'Wellness programs',
            icon: 'fas fa-spa',
        },
        employee_assistance_programme: {
            name: 'Employee Assistance Programme',
            icon: 'fas fa-hands-helping',
        },
        parental_leave: {
            name: 'Additional parental leave',
            icon: 'fas fa-baby',
        },
        eve_sleep_weird_label: {
            name: 'Enhanced maternity and paternity leave',
            icon: 'fas fa-baby',
        },
        paid_emergency_leave: {
            name: 'Paid emergency leave',
            icon: 'fas fa-hand-holding-medical',
        },
        extra_holiday: {
            name: 'Extra holiday',
            icon: 'fas fa-umbrella-beach',
        },
        sabbatical_opportunities: {
            name: 'Sabbatical Opportunities',
            icon: 'fas fa-calendar-check',
        },
        professional_development: {
            name: 'Professional development',
            icon: 'fas fa-lightbulb',
        },
        mentoring_coaching: {
            name: 'Mentoring/coaching',
            icon: 'fas fa-hands-helping',
        },
        paid_volunteering: {
            name: 'Paid volunteer days',
            icon: 'fas fa-hand-holding-heart',
        },
        payroll_giving: {
            name: 'Payroll giving',
            icon: 'fas fa-hand-holding-usd'
        },
        flexibile_benefits_scheme: {
            name: 'Flexible benefits scheme',
            icon: 'fab fa-buromobelexperte'
        },
        salary_sacrifice: {
            name: 'Salary sacrifice',
            icon: 'fas fa-money-bill-wave',
        },
        team_social_events: {
            name: 'Team social events',
            icon: 'fas fa-users',
        },
        team_lunches: {
            name: 'Team lunches',
            icon: 'fas fa-utensils',
        },
        extracurricular_clubs: {
            name: 'Extracurricular clubs',
            icon: 'fas fa-book-open', 
        },
        equipment_allowance: {
            name: 'Equipment allowance',
            icon: 'fas fa-laptop',
        },
        cycle_to_work: {
            name: 'Cycle to work scheme',
            icon: 'fas fa-bicycle',
        },
        free_fruit: {
            name: 'Free fruit',
            icon: 'fas fa-apple-alt',
        },
        free_soft_drinks: {
            name: 'Free soft drinks',
            icon: 'fas fa-mug-hot',
        },
        dogs_in_the_office: {
            name: 'Dogs in the office',
            icon: 'fas fa-paw',
        },
    },
}
