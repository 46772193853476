<template>
    <div :class="$style.wrapper">
        <CandidateFeedbackCard
            v-if="!feedbackFetchPending"
            :averageRating="averageRating"
            :feedbackCount="allFeedbackCount"
            :textFeedbackCount="textFeedbackCount"
            :feedbacks="feedback.feedback"
        />
    </div>
</template>

<script>
import CandidateFeedbackCard from './CandidateFeedbackCard/CandidateFeedbackCard'

export default {
    name: 'ActivityFeed',
    components: {
        CandidateFeedbackCard,
    },
    computed: {
        feedback() {
            return this.$store.state.feedback
        },
        feedbackFetchPending() {
            return this.$store.state.pending.fetchFeedback
        },
        averageRating() {
            return this.feedback.averageRating ? Math.round(this.feedback.averageRating) : 0
        },
        allFeedbackCount() {
            return this.feedback.allFeedbackCount || 0
        },
        textFeedbackCount() {
            return this.feedback.textFeedbackCount || 0
        },
    },
}
</script>

<style module src="./ActivityFeed.css" />
