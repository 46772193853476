var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.timePeriodContainer},[_c('span',{class:_vm.$style.datePickerRow},[_c('span',{class:[_vm.$style.datePickerWrap, _vm.$style.datePickerSpacing]},[_c('p',{class:_vm.$style.textSmallBody},[_vm._v("Start date")]),_c('DatePicker',{attrs:{"mode":"date","popover":{
                    visibility: _vm.disabled ? 'hidden' : 'focus',
                    positionFixed: true,
                },"is-required":"","max-date":_vm.range.end},on:{"input":_vm.emitDatesChanged},scopedSlots:_vm._u([{key:"header-title",fn:function({ monthLabel, yearLabel }){return [_vm._v(" "+_vm._s(monthLabel)+" "+_vm._s(yearLabel)+" "),_c('FontAwesomeIcon',{class:_vm.$style.datepickerChevronIcon,attrs:{"icon":_vm.chevronDownIcon}})]}},{key:"default",fn:function({ updateValue, showPopover }){return [_c('div',{class:_vm.$style.dateInputContainer},[_c('TextInput',{class:_vm.$style.dateInput,attrs:{"ariaLabel":"stats period date start","value":_vm.rangeStartText,"disabled":_vm.disabled},on:{"focus":showPopover,"change":updateValue}}),_c('FontAwesomeIcon',{class:_vm.$style.calendarIcon,attrs:{"icon":_vm.calendarIcon}})],1)]}}]),model:{value:(_vm.range.start),callback:function ($$v) {_vm.$set(_vm.range, "start", $$v)},expression:"range.start"}})],1),_c('span',{class:_vm.$style.datePickerWrap},[_c('p',{class:_vm.$style.textSmallBody},[_vm._v("End date")]),_c('DatePicker',{attrs:{"mode":"date","popover":{
                    visibility: _vm.disabled ? 'hidden' : 'focus',
                    positionFixed: true,
                },"is-required":"","min-date":_vm.range.start},on:{"input":_vm.emitDatesChanged},scopedSlots:_vm._u([{key:"header-title",fn:function({ monthLabel, yearLabel }){return [_vm._v(" "+_vm._s(monthLabel)+" "+_vm._s(yearLabel)+" "),_c('FontAwesomeIcon',{class:_vm.$style.datepickerChevronIcon,attrs:{"icon":_vm.chevronDownIcon}})]}},{key:"default",fn:function({ updateValue, showPopover }){return [_c('div',{class:_vm.$style.dateInputContainer},[_c('TextInput',{class:_vm.$style.dateInput,attrs:{"ariaLabel":"stats period date end","value":_vm.rangeEndText,"disabled":_vm.disabled},on:{"focus":showPopover,"change":updateValue}}),_c('FontAwesomeIcon',{class:_vm.$style.calendarIcon,attrs:{"icon":_vm.calendarIcon}})],1)]}}]),model:{value:(_vm.range.end),callback:function ($$v) {_vm.$set(_vm.range, "end", $$v)},expression:"range.end"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }