<template>
    <div :class="$style.timePeriodContainer">
        <span :class="$style.datePickerRow">
            <span :class="[$style.datePickerWrap, $style.datePickerSpacing]">
                <p :class="$style.textSmallBody">Start date</p>
                <DatePicker
                    v-model="range.start"
                    mode="date"
                    @input="emitDatesChanged"
                    :popover="{
                        visibility: disabled ? 'hidden' : 'focus',
                        positionFixed: true,
                    }"
                    is-required
                    :max-date="range.end"
                >
                    <template #header-title="{ monthLabel, yearLabel }">
                        {{ monthLabel }} {{ yearLabel }}
                        <FontAwesomeIcon
                            :class="$style.datepickerChevronIcon"
                            :icon="chevronDownIcon"
                        />
                    </template>
                    <template #default="{ updateValue, showPopover }">
                        <div :class="$style.dateInputContainer">
                            <TextInput
                                :class="$style.dateInput"
                                ariaLabel="stats period date start"
                                :value="rangeStartText"
                                :disabled="disabled"
                                @focus="showPopover"
                                @change="updateValue"
                            />
                            <FontAwesomeIcon :icon="calendarIcon" :class="$style.calendarIcon" />
                        </div>
                    </template>
                </DatePicker>
            </span>
            <span :class="$style.datePickerWrap">
                <p :class="$style.textSmallBody">End date</p>
                <DatePicker
                    v-model="range.end"
                    mode="date"
                    @input="emitDatesChanged"
                    :popover="{
                        visibility: disabled ? 'hidden' : 'focus',
                        positionFixed: true,
                    }"
                    is-required
                    :min-date="range.start"
                >
                    <template #header-title="{ monthLabel, yearLabel }">
                        {{ monthLabel }} {{ yearLabel }}
                        <FontAwesomeIcon
                            :class="$style.datepickerChevronIcon"
                            :icon="chevronDownIcon"
                        />
                    </template>
                    <template #default="{ updateValue, showPopover }">
                        <div :class="$style.dateInputContainer">
                            <TextInput
                                :class="$style.dateInput"
                                ariaLabel="stats period date end"
                                :value="rangeEndText"
                                :disabled="disabled"
                                @focus="showPopover"
                                @change="updateValue"
                            />
                            <FontAwesomeIcon :icon="calendarIcon" :class="$style.calendarIcon" />
                        </div>
                    </template>
                </DatePicker>
            </span>
        </span>
    </div>
</template>

<script>
import { format as formatDate, subMonths, startOfDay, endOfDay } from 'date-fns'
import { DatePicker } from 'v-calendar'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faQuestionCircle, faChevronDown, faCalendarDay } from '@fortawesome/pro-light-svg-icons'
import { TextInput } from '@applied/marmot'

export default {
    name: 'TimePeriodSelectionBar',
    components: {
        DatePicker,
        FontAwesomeIcon,
        TextInput,
    },
    props: {
        disabled: Boolean,
    },
    data() {
        return {
            range: {
                start: subMonths(new Date(), 3),
                end: new Date(),
            },
        }
    },
    mounted() {
        this.emitDatesChanged()
    },
    computed: {
        questionCircleIcon() {
            return faQuestionCircle
        },
        chevronDownIcon() {
            return faChevronDown
        },
        calendarIcon() {
            return faCalendarDay
        },
        rangeStartText() {
            return this.formatDate(this.range.start)
        },
        rangeEndText() {
            return this.formatDate(this.range.end)
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date, 'dd/MM/yyyy')
        },
        emitDatesChanged() {
            this.$emit('datesChanged', {
                startDate: startOfDay(this.range.start),
                endDate: endOfDay(this.range.end),
            })
        },
    },
}
</script>

<style module src="./TimePeriodSelectionBar.css" />
