/* eslint-disable no-param-reassign */
import axios from '@/axios'

const fetchOrg = (context) => {
    context.commit('pending', { type: 'fetchOrg', status: true })
    context.commit('error', { type: 'fetchOrg', status: false })

    const url = `/api/org/current`

    return axios
        .get(url, {})
        .then((response) => {
            context.commit('storeOrg', response.data)
            context.commit('storeFreeTrial', response.data.OrgTiers[0])
        })
        .catch(() => {
            context.commit('error', {
                type: 'fetchOrg',
                status: true,
            })
        })
        .finally(() => {
            context.commit('pending', {
                type: 'fetchOrg',
                status: false,
            })
        })
}

const fetchMicrositeData = (context) => {
    context.commit('pending', { type: 'fetchMicrositeData', status: true })
    context.commit('error', { type: 'fetchMicrositeData', status: false })

    const url = `/api/org/micrositeData`

    return axios
        .get(url)
        .then((response) => {
            context.commit('storeMicrositeData', response.data.customOrgData)
        })
        .catch(() => {
            context.commit('error', {
                type: 'fetchMicrositeData',
                status: true,
            })
        })
        .finally(() => {
            context.commit('pending', {
                type: 'fetchMicrositeData',
                status: false,
            })
        })
}

const fetchOrgAdmins = (context) => {
    context.commit('error', { type: 'fetchOrgAdmins', status: false })
    context.commit('pending', { type: 'fetchOrgAdmins', status: true })
    const orgId = context.state.user.OrgId
    const url = `/api/org/${orgId}/admins`
    // todo add pending state + error state
    return axios
        .get(url, {})
        .then((response) => {
            context.commit('storeOrgUsers', response.data)
            context.commit('pending', {
                type: 'fetchOrgAdmins',
                status: false,
            })
            return Promise.resolve()
        })
        .catch(() => {
            context.commit('pending', {
                type: 'fetchOrgAdmins',
                status: false,
            })
            context.commit('error', {
                type: 'fetchOrgAdmins',
                status: true,
            })
            return Promise.reject()
        })
}

const fetchOrgStats = (context, { from, until, filters }) => {
    context.commit('pending', { type: 'fetchOrgStats', status: true })
    context.commit('error', { type: 'fetchOrgStats', status: false })

    if (
        context.state.org.hasGlobalReporting === false &&
        context.state.user.id &&
        !context.state.user.inImpersonatedAccount // Only do this clause if org and use data have already been loaded
    ) {
        context.commit('pending', { type: 'fetchOrgStats', status: false })
        return Promise.resolve()
    }

    // Grab the UTC date without using an external library.
    const formattedFrom = from.toISOString()
    const formattedUntil = until.toISOString()
    const teams = filters && filters.team && filters.team.map((x) => x.value).join(',')
    const grades = filters && filters.grade && filters.grade.map((x) => x.value).join('||') // grades may have commas in them!

    const dataSources = [
        {
            url: `/api/org/stats`,
            key: 'overall',
            params: {
                from: formattedFrom,
                until: formattedUntil,
                teams,
                grades,
            },
        },
        {
            url: `/api/org/hired-stats`,
            key: 'hired',
            params: {
                from: formattedFrom,
                until: formattedUntil,
                teams,
                grades,
            },
        },
        {
            url: `/api/org/rejected-stats`,
            key: 'rejected',
            params: {
                from: formattedFrom,
                until: formattedUntil,
                teams,
                grades,
            },
        },
        {
            url: `/api/recent-feedback`,
            key: 'postApply',
            params: {
                size: 20,
                type: 'post-apply',
                from: formattedFrom,
                until: formattedUntil,
                teams,
                grades,
            },
        },
        {
            url: `/api/recent-feedback`,
            key: 'postFeedback',
            params: {
                size: 20,
                type: 'post-feedback',
                from: formattedFrom,
                until: formattedUntil,
                teams,
                grades,
            },
        },
        {
            url: `/api/recent-feedback`,
            key: 'postReview',
            params: {
                size: 20,
                type: 'post-review',
                from: formattedFrom,
                until: formattedUntil,
                teams,
                grades,
            },
        },
        {
            url: `/api/org/referrer-breakdown`,
            key: 'sourcing',
            params: {
                from: formattedFrom,
                until: formattedUntil,
                teams,
                grades,
            },
        },
        {
            url: `/api/org/drop-off`,
            key: 'demographicsByStep',
            params: {
                from: formattedFrom,
                until: formattedUntil,
                teams,
                grades,
            },
        },
        {
            url: `/api/org/drop-off`,
            key: 'demographicsByStepExcludingQuickApply',
            params: {
                from: formattedFrom,
                until: formattedUntil,
                teams,
                grades,
                excludeQuickApply: true,
            },
        },
    ]

    const result = {}

    const requestPromises = dataSources.map((dataSource) =>
        axios.get(dataSource.url, { params: dataSource.params }).then((response) => {
            result[dataSource.key] = response.data
        }),
    )

    return Promise.all(requestPromises)
        .then(() => {
            context.commit('storeStats', result)
        })
        .catch((err) => {
            console.log(`Error fetching org stats, ${err}`)
            context.commit('error', { type: 'fetchOrgStats', status: true })
        })
        .finally(() => {
            context.commit('pending', { type: 'fetchOrgStats', status: false })
        })
}

const fetchFeedback = (context) => {
    const url = `/api/activity`
    const type = 'fetchFeedback'
    context.commit('error', { type, status: false })
    context.commit('pending', { type, status: true })
    return axios
        .get(url, {})
        .then((response) => {
            context.commit('storeFeedback', response.data)
            return Promise.resolve()
        })
        .catch(() => {
            context.commit('error', { type, status: true })
        })
        .finally(() => {
            context.commit('pending', { type, status: false })
        })
}

const fetchTemplates = (context) => {
    const url = `/templates`
    return axios.get(url, {}).then((response) => {
        context.commit('storeTemplates', response.data)
        return Promise.resolve()
    })
}

const fetchOrgSubBrands = (context) => {
    const type = 'fetchOrgSubBrands'
    const orgId = context.state.org.id || context.state.user.OrgId

    context.commit('error', { type, status: false })
    context.commit('pending', { type, status: true })

    return axios
        .get(`/api/org/${orgId}/brands`)
        .then((response) => {
            context.commit('pending', { type, status: false })
            context.commit('storeSubBrands', response.data)
        })
        .catch(() => {
            context.commit('error', { type, status: true })
            context.commit('pending', { type, status: false })
        })
}

const addNewAdmin = (context, data) => {
    context.commit('error', { type: 'addNewAdmin', status: false })
    context.commit('pending', { type: 'addNewAdmin', status: true })
    const url = `/org/${data.orgId}/admin`
    return axios
        .post(url, data)
        .then(() => {
            context.dispatch('fetchOrgAdmins')
            context.commit('pending', { type: 'addNewAdmin', status: false })
        })
        .catch(() => {
            context.commit('pending', { type: 'addNewAdmin', status: false })
            context.commit('error', { type: 'addNewAdmin', status: true })
        })
}

const updateOrg = (context, data) => {
    context.commit('error', { type: 'updateOrg', status: false })
    context.commit('pending', { type: 'updateOrg', status: true })
    const orgId = data.id || context.state.user.OrgId
    const url = `api/rest/org/${orgId}`

    return axios
        .put(url, data)
        .then((response) => {
            context.commit('pending', { type: 'updateOrg', status: false })
            context.commit('storeOrg', response.data)
        })
        .catch(() => {
            context.commit('pending', { type: 'updateOrg', status: false })
            context.commit('error', { type: 'updateOrg', status: true })
        })
}

const grantOrRevokeAccess = (context, data) => {
    context.commit('error', { type: 'grantOrRevokeAccess', status: false })
    context.commit('pending', { type: 'grantOrRevokeAccess', status: true })
    const url = `api/account/${data.userId}/${data.action}`
    return axios
        .post(url, data)
        .then(() => {
            context.dispatch('fetchOrgAdmins')
            context.commit('pending', {
                type: 'grantOrRevokeAccess',
                status: false,
            })
        })
        .catch(() => {
            context.commit('pending', {
                type: 'grantOrRevokeAccess',
                status: false,
            })
            context.commit('error', {
                type: 'grantOrRevokeAccess',
                status: true,
            })
        })
}

const deactivateUser = (context, data) => {
    context.commit('error', { type: 'deactivateUser', status: false })
    context.commit('pending', { type: 'deactivateUser', status: true })
    const url = `api/account/users/${data.userId}/deactivate`
    return axios
        .post(url, data)
        .then(() => {
            context.dispatch('fetchOrgAdmins')
            context.commit('pending', {
                type: 'deactivateUser',
                status: false,
            })
            context.commit('error', {
                type: 'deactivateUser',
                status: false,
            })
        })
        .catch(() => {
            context.commit('pending', {
                type: 'deactivateUser',
                status: false,
            })
            context.commit('error', {
                type: 'deactivateUser',
                status: true,
            })
        })
}

const createNewOrg = (context, data) => {
    const orgSignupDetails = {
        ...context.state.orgSignupData,
        signupReason: data,
    }

    return axios.post('/register/new-org', orgSignupDetails)
}

const createNewOrgMinimal = (context, data) =>
    axios.post('/signup-with-password', data).then(() => {
        context.commit('storeOrgSignupData', data)
    })

const freeTrialAddSampleRole = () =>
    axios
        .post('/free-trial/add-sample-role')
        .then(() => Promise.resolve())
        .catch(() => Promise.reject())

const updateMicrositeData = (context, data) => {
    context.commit('error', { type: 'updateMicrositeData', status: false })
    context.commit('pending', { type: 'updateMicrositeData', status: true })
    return axios
        .post('/org/update-microsite-details', data)
        .then((response) => {
            context.commit('storeMicrositeData', response.data.data)
            context.commit('pending', {
                type: 'updateMicrositeData',
                status: false,
            })
            return Promise.resolve()
        })
        .catch(() => {
            context.commit('error', {
                type: 'updateMicrositeData',
                status: true,
            })
            context.commit('pending', {
                type: 'updateMicrositeData',
                status: false,
            })
            return Promise.reject()
        })
}

const fetchLibrarySkills = (context, data) => {
    const { questionType, includeOrgLibrary, includeAppliedLibrary } = data
    const type = 'fetchLibrarySkills'

    context.commit('pending', { type, status: true })
    context.commit('error', { type, status: false })

    return axios
        .get(`/api/questions/library-question-skills`, {
            params: {
                type: questionType,
                includeOrgLibrary,
                includeAppliedLibrary,
            },
        })
        .then((response) => {
            context.commit('storeLibrarySkills', response.data.skills)
        })
        .catch(() => {
            context.commit('pending', { type, status: false })
            context.commit('error', { type, status: true })
        })
        .finally(() => {
            context.commit('pending', { type, status: false })
        })
}

const archiveLibraryQuestion = (context, data) => {
    const { questionId } = data
    const type = 'archiveLibraryQuestion'

    context.commit('pending', { type, status: true })
    context.commit('error', { type, status: false })

    return axios
        .post(`/api/question/${questionId}/archive`)
        .then(() => {
            context.commit('pending', { type, status: false })
        })
        .catch(() => {
            context.commit('pending', { type, status: false })
            context.commit('error', { type, status: true })
        })
}

const sendGlobalReportingAccessRequestEmail = (context) => {
    context.commit('error', {
        type: 'sendGlobalReportingAccessRequestEmail',
        status: false,
    })
    context.commit('pending', {
        type: 'sendGlobalReportingAccessRequestEmail',
        status: true,
    })

    return axios
        .post('/account/send-global-reporting-access-request', {})
        .catch(() => {
            context.commit('error', {
                type: 'sendGlobalReportingAccessRequestEmail',
                status: true,
            })
        })
        .finally(() => {
            context.commit('pending', {
                type: 'sendGlobalReportingAccessRequestEmail',
                status: false,
            })
        })
}

const grantOrRevokeOrgLibraryAccess = (context, data) => {
    const type = 'grantOrRevokeOrgLibraryAccess'
    context.commit('error', { type, status: false })
    context.commit('pending', { type, status: true })

    return axios
        .post('account/features/limit-org-library-access', data)
        .then(() => {
            context.commit('pending', { type, status: false })
        })
        .catch(() => {
            context.commit('error', { type, status: true })
            context.commit('pending', { type, status: false })
        })
}

const setRoleApproval = (context, data) => {
    const type = 'setRoleApproval'
    context.commit('error', { type, status: false })
    context.commit('pending', { type, status: true })

    return axios
        .post('account/features/role-approval', { enabled: data.enabled })
        .catch((e) => {
            context.commit('error', { type, status: true })
            return Promise.reject(e)
        })
        .finally(() => {
            context.commit('pending', { type, status: false })
        })
}

const updateWebhook = (context, data) => {
    const type = 'updateWebhook'
    context.commit('error', { type, status: false })
    context.commit('pending', { type, status: true })

    const payload = {
        ...data,
        orgId: context.state.user.OrgId,
    }

    return axios
        .post(`api/org/webhook`, payload)
        .catch(() => {
            context.commit('error', { type, status: true })
        })
        .finally(() => {
            context.commit('pending', { type, status: false })
        })
}

const purgeOrgLogoCache = (context, orgId) => {
    const type = 'purgeOrgLogoCache'

    context.commit('pending', { type, status: true })
    context.commit('error', { type, status: false })

    return axios
        .post(`/api/org/${orgId}/purge-logo-cache`)
        .then(() => {
            context.commit('pending', { type, status: false })
        })
        .catch(() => {
            context.commit('pending', { type, status: false })
            context.commit('error', { type, status: true })
        })
}

export default {
    fetchOrgAdmins,
    fetchOrg,
    addNewAdmin,
    grantOrRevokeAccess,
    deactivateUser,
    updateOrg,
    fetchFeedback,
    fetchOrgStats,
    fetchTemplates,
    fetchOrgSubBrands,
    fetchLibrarySkills,
    createNewOrg,
    createNewOrgMinimal,
    freeTrialAddSampleRole,
    fetchMicrositeData,
    updateMicrositeData,
    archiveLibraryQuestion,
    sendGlobalReportingAccessRequestEmail,
    grantOrRevokeOrgLibraryAccess,
    setRoleApproval,
    updateWebhook,
    purgeOrgLogoCache,
}
