export default [
    {
        name: 'Gender',
        key: 'gender',
    },
    {
        name: 'Age',
        key: 'ageRange',
    },
    {
        name: 'Ethnicity',
        key: 'ethnicity',
    },
    {
        name: 'Sexuality',
        key: 'sexuality',
    },
    {
        name: 'Disability',
        key: 'disability',
    },
    {
        name: 'Family Education',
        key: 'familyEducation',
    },
    {
        name: 'Family Wealth',
        key: 'familyWealth',
    },
    {
        name: 'Religion',
        key: 'religion',
    },
    {
        name: 'Caste',
        key: 'caste',
    },
    {
        name: 'Veteran Status',
        key: 'veteranStatus',
    },
    {
        name: 'Community Background',
        key: 'communityBackground',
    },
]
