<template>
    <div
        :class="{
            [$style.wrapper]: true,
            [$style.responsiveLayout]: responsiveLayout,
        }"
        :href="link || feedbackReportLink"
    >
        <p :class="$style.title">Candidate rating</p>
        <div :class="$style.ratingContainer">
            <StarRating :stars="10" :startingRating="averageRating" static small />
            <div :class="[$style.extraDetails]" v-if="feedbackCount > 0">
                <div :class="$style.flexRow">
                    <div :class="$style.timePeriod">Last 90 days</div>
                    <div :class="$style.detailWrapper">
                        <FontAwesomeIcon :icon="userIcon" :class="$style.faIcon" />
                        <p>{{ feedbackCount }}</p>
                    </div>
                </div>
                <div :class="$style.detailWrapper">
                    <Button color="primaryText" @clicked="showComments = !showComments">
                        <template v-slot:buttonText>
                            <p :class="$style.toggleCommentInner">{{ commentLabel }}</p>
                        </template>
                    </Button>
                </div>
            </div>
            <div v-else :class="[$style.emptyState, $style.paddingTop]">
                <p>Nobody has given feedback yet</p>
            </div>
        </div>
        <div v-show="showComments" :class="$style.feedbackWrapper">
            <UserFeedbackItem
                v-for="feedbackItem in feedbacks"
                :feedback="feedbackItem"
                :key="feedbackItem.id"
            />
        </div>
    </div>
</template>

<script>
import { faComment } from '@fortawesome/pro-light-svg-icons'
import { faUsers } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Button, StarRating } from '@applied/marmot'
import UserFeedbackItem from '../UserFeedbackItem/UserFeedbackItem'

export default {
    name: 'CandidateFeedbackCard',
    components: {
        StarRating,
        Button,
        FontAwesomeIcon,
        UserFeedbackItem,
    },
    props: {
        averageRating: {
            type: Number,
            default: 0,
            required: true,
        },
        feedbackCount: {
            type: Number,
            default: 0,
            required: true,
        },
        textFeedbackCount: {
            type: Number,
            default: 0,
            required: true,
        },
        link: {
            type: String,
            required: false,
        },
        responsiveLayout: {
            type: Boolean,
            default: false,
            required: false,
        },
        feedbacks: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showComments: false,
        }
    },
    computed: {
        userIcon() {
            return faUsers
        },
        feedbackIcon() {
            return faComment
        },
        feedbackReportLink() {
            return `/manage/${this.jobId}/reports/experience`
        },
        commentLabel() {
            const pluralised = this.textFeedbackCount === 1 ? 'comment' : 'comments'
            const visibility = this.showComments ? 'Hide' : 'Show'
            return `${visibility} ${this.textFeedbackCount} ${pluralised}`
        },
    },
}
</script>

<style module src="./CandidateFeedbackCard.css" />
