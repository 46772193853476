<template>
    <div :class="$style.wrapper">
        <Button data-test-id="createRoleButton" @clicked="toggleModal" color="primary">
            <span slot="buttonText">Start a new role</span>
        </Button>
        <CreateRoleModal v-if="modalOpen" />
    </div>
</template>

<script>
import { Button } from '@applied/marmot'
import CreateRoleModal from './CreateRoleModal/CreateRoleModal'

export default {
    name: 'CreateRole',
    components: {
        Button,
        CreateRoleModal,
    },
    methods: {
        toggleModal() {
            this.$store.commit('toggleModalVisibility', {
                modalName: 'createRoleModal',
            })
        },
    },
    computed: {
        modalOpen() {
            return this.$store.state.modalVisibility.createRoleModal
        },
    },
}
</script>

<style module src="./CreateRole.css" />
