/* eslint-disable vue-a11y/click-events-have-key-events */
<template>
    <div :class="$style.wrapper">
        <p :class="$style.jobCount">Showing {{ jobCount || 0 }} Jobs</p>
        <div :class="$style.chipList">
            <Chip v-for="item in items" :key="item.value" :class="$style.chip" color="great">
                <template v-slot:body>
                    <p :class="$style.chipText">{{ item.name }}</p>
                    <button
                        name="remove filter"
                        @click="removeClicked(item)"
                        @keydown="buttonKeydown($event, () => removeClicked(item))"
                        :class="$style.chipRemove"
                    >
                        <FontAwesomeIcon :icon="closeIcon" />
                    </button>
                </template>
            </Chip>
        </div>
        <button
            v-if="items.length"
            name="remove all filters"
            @click="clearAllClicked"
            @keydown="buttonKeydown($event, clearAllClicked)"
            :class="$style.clearChips"
        >
            Clear filters
        </button>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Chip } from '@applied/marmot'

export default {
    name: 'ActiveFilterList',
    props: {
        jobCount: Number,
        items: Array,
    },
    components: {
        FontAwesomeIcon,
        Chip,
    },
    methods: {
        buttonKeydown(event, callback) {
            // Space key or enter key
            if (event.which in [13, 32]) {
                callback()
            }
        },
        removeClicked(item) {
            this.$emit('remove-filter', item)
        },
        clearAllClicked() {
            this.$emit('remove-all', this.items)
        },
    },
    computed: {
        closeIcon() {
            return faTimes
        },
    },
}
</script>

<style module src="./ActiveFilterList.css" />
