<template>
    <div
        :class="{
            [$style.pageWrap]: true,
            [$style.wideLayout]: wideLayout,
            [$style.noPaddingTop]: !hasPaddingTop,
            [$style.noPaddingBottom]: !hasPaddingBottom,
            [$style.flexColumn]: isFlexDirectionColumn,
        }"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'PageLayout',
    props: {
        wideLayout: Boolean,
        hasPaddingTop: { 
            default: true, 
            type: Boolean 
        },
        hasPaddingBottom: { 
            default: true, 
            type: Boolean 
        },
        isFlexDirectionColumn: { 
            default: false, 
            type: Boolean 
        },
    },
}
</script>

<style module src="./PageLayout.css" />
