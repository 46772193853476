<template>
    <div :class="$style.wrapper">
        <ArchiveConfirmationModal
            v-if="showArchiveModal"
            :JobId="jobData.id"
            @close="toggleArchiveModal"
        />
        <div>
            <div :class="$style.jobInner">
                <div :class="[$style.row, $style.underline, $style.noPadBottom]">
                    <div :class="$style.column">
                        <a :href="getPhaseDetails.ctaLink" style="text-decoration: none">
                            <h3 :class="$style.title">{{ jobData.title }}</h3>
                        </a>
                        <p :class="$style.details">
                            {{
                                `${jobData.User ? jobData.User.firstName : user.firstName} ${
                                    jobData.User ? jobData.User.lastName : user.lastName
                                }`
                            }}
                            <span v-if="jobData.publishedAtText"
                                >, posted {{ jobData.publishedAtText }}
                            </span>
                        </p>
                    </div>
                    <div :class="[$style.tagContainer, $style.column, $style.flexEnd]">
                        <div :class="$style.flexWrap">
                            <Tag
                                :class="$style.statusTag"
                                :text="getPhaseDetails.jobText"
                                :color="getPhaseDetails.tagColour"
                            ></Tag>
                            <div :class="$style.dotMenuWrapper">
                                <DotMenu
                                    :menuOptions="dotMenuOptions"
                                    :disabled="!enableDotMenu"
                                    align="right"
                                    color="gray"
                                    ariaLabel="More Job Actions Menu"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="[$style.row, $style.statsRow]">
                    <div :class="$style.pipelineStatsWrapper">
                        <PipelineStatsSection
                            :jobId="job.id"
                            :reviewProgress="jobData.reviewCounts"
                            :pipelineStats="jobData.pipelineStats"
                        />
                    </div>
                    <div :class="$style.flexWrap">
                        <div :class="$style.applicantCountSection">
                            <span :class="$style.applicantCount">{{
                                jobData.submittedApplicationCount
                            }}</span>
                            Applications
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DotMenu } from '@applied/marmot'
import Tag from '@/components/Tag/Tag'
import PipelineStatsSection from './PipelineStatsSection/PipelineStatsSection'
import ArchiveConfirmationModal from './ArchiveConfirmationModal/ArchiveConfirmationModal'

export default {
    name: 'JobCard',
    props: {
        job: Object,
    },
    components: {
        PipelineStatsSection,
        ArchiveConfirmationModal,
        DotMenu,
        Tag,
    },
    data() {
        return {
            showArchiveModal: false,
        }
    },
    methods: {
        toggleArchiveModal() {
            this.showArchiveModal = !this.showArchiveModal
        },
        handleCopy() {
            if (!this.isAdmin) {
                return
            }
            this.$store.dispatch('copyRoleById', {
                jobId: this.jobData.id,
                fromTemplate: false,
            })
            this.$emit('scollToTop')
        },
        handleArchive() {
            if (!this.isAdmin) {
                return null
            }
            if (this.getPhaseDetails.jobText.indexOf('Open') > -1) {
                return this.toggleArchiveModal()
            }
            return this.$store.dispatch('archiveRole', this.jobData.id)
        },
        handleUnarchive() {
            this.$store.dispatch('unarchiveRole', this.jobData.id)
        },
    },
    computed: {
        jobData() {
            return {
                ...this.job,
                submittedApplicationCount:
                    this.job.submittedApplicationCount ||
                    (this.job.Applications ? this.job.Applications.length : 0),
                timeTillClosing:
                    this.job.timeTillClosing ||
                    (this.job.addedData && this.job.addedData.timeTillClosing),
                publishedAtText: this.job.publishedAtShortDescription,
                overallPhase:
                    this.job.overallPhase || (this.job.addedData && this.job.addedData.phase),
                path: this.job.path || (this.job.addedData && this.job.addedData.path),
            }
        },
        isAdmin() {
            return this.user.role === 'ADMIN'
        },
        user() {
            return this.$store.state.user ? this.$store.state.user : {}
        },
        jobUserIds() {
            return this.job.JobUsers.map((jobUser) => jobUser.UserId)
        },
        dotMenuOptions() {
            return [
                {
                    id: 1,
                    displayName: 'Duplicate job',
                    disabled: !this.isAdmin,
                    clickHander: this.handleCopy,
                },
                {
                    id: 2,
                    displayName: this.jobData.archived ? 'Unarchive job' : 'Archive job',
                    disabled: !this.isAdmin,
                    clickHander: this.jobData.archived ? this.handleUnarchive : this.handleArchive,
                },
            ]
        },
        enableDotMenu() {
            return this.dotMenuOptions.some((option) => option.disabled === false)
        },
        getPhaseDetails() {
            let jobText
            let tagColour
            const jobPath = this.jobData.path
            let ctaLink = `/role/${this.jobData.id}/${jobPath}`
            if (jobPath === 'draft') ctaLink = `/builder/${this.jobData.id}/`
            if (jobPath === 'live') ctaLink = `/manage/${this.jobData.id}/`
            if (jobPath === 'analysis') {
                ctaLink = `/manage/${this.jobData.id}/reports`
            }

            const jobPhase = this.jobData.overallPhase
            switch (jobPhase) {
                case 'ARCHIVED':
                    jobText = 'Archived'
                    tagColour = 'disabled'
                    break
                case 'CLOSED':
                    jobText = this.jobData.timeTillClosing || 'Closed'
                    tagColour = 'yellow'
                    break
                case 'DRAFT':
                    jobText = 'Draft'
                    ctaLink = `/builder/${this.jobData.id}`
                    tagColour = 'blue'
                    break
                default:
                    jobText = this.jobData.timeTillClosing ? this.jobData.timeTillClosing : 'Open'
                    tagColour = 'green'
                    if (this.jobData.sampleRole) {
                        ctaLink = `${ctaLink}?appcue=e427fbc1-26c3-45fe-bbfc-445fad7031c9`
                    }
            }

            return {
                jobText,
                ctaLink,
                tagColour,
            }
        },
    },
}
</script>

<style module src="./JobCard.css" />
