<template>
    <div :class="$style.downloadsSection">
        <h4 :class="[$style.cardTitle, $style.smallMarginBottom]">Downloads</h4>
        <p :class="$style.topLine">
            Prefer to wrangle with this data yourself? You can download .csv files below to analyse
            as you wish.
        </p>
        <div :class="$style.downloadCardsContainer">
            <Card
                :class="$style.downloadCard"
                v-for="downloadCard in downloadCards"
                v-bind:key="downloadCard.title"
            >
                <div :class="$style.descriptionContainer">
                    <h5 :class="$style.downloadCardTitle">
                        {{ downloadCard.title }}
                    </h5>
                    <p v-html="downloadCard.description" :class="$style.downloadDescription"></p>
                </div>
                <p :class="$style.candidateCounts">
                    <strong>{{ getCandidateCountsNumber(downloadCard.key) }}</strong>
                    {{ candidateCounts[downloadCard.key] === 1 ? 'candidate' : 'candidates' }}
                </p>
                <Button :class="$style.downloadButton" @clicked="downloadCard.downloadFunction">
                    <span slot="buttonText"> Download </span>
                </Button>
            </Card>
        </div>
    </div>
</template>

<script>
import { Button, Card } from '@applied/marmot'

export default {
    name: 'DownloadsSection',
    components: {
        Button,
        Card,
    },
    props: {
        dateRange: Object,
        filters: Object,
        candidateCounts: { type: Object, required: true },
    },
    data() {
        return {
            downloadCards: [
                {
                    key: 'demographic',
                    title: 'Demographic data for submitted applications (.CSV file)',
                    description:
                        'Includes data for all candidates who submitted applications during this period.<br />Does not include hired candidates who applied outside this time period.',
                    downloadFunction: this.downloadDemographicCSV,
                },
                {
                    key: 'all',
                    title: 'List of all candidates with submitted applications (.CSV file)',
                    description:
                        'Includes data for all candidates who submitted applications during this time period.<br />Does not include hired candidates who applied outside this time period.',
                    downloadFunction: this.downloadSubmittedCSV,
                },
                {
                    key: 'hired',
                    title: 'List of all hired candidates (.CSV file)',
                    description:
                        'Includes data for all candidates who were hired from applications submitted during this period.',
                    downloadFunction: this.downloadHiredCSV,
                },
            ],
        }
    },
    computed: {
        startDate() {
            return new Date(this.dateRange.from).toISOString()
        },
        endDate() {
            return new Date(this.dateRange.until).toISOString()
        },
        teamsString() {
            const teams = this.filters?.team?.map((x) => x.value).join(',')
            return teams ? `&teams=${teams}` : ``
        },
        gradesString() {
            const grades = this.filters?.grade?.map((x) => x.value).join('||')
            return grades ? `&grades=${grades}` : ``
        },
    },
    methods: {
        downloadDemographicCSV() {
            window.open(
                `/api/org/org-drop-off/csv?from=${this.startDate}&until=${this.endDate}${this.teamsString}${this.gradesString}`,
            )
        },
        downloadSubmittedCSV() {
            window.open(
                // eslint-disable-next-line max-len
                `/api/applicant/list/csv?start=${this.startDate}&end=${this.endDate}${this.teamsString}${this.gradesString}&rangeType=submitted`,
            )
        },
        downloadHiredCSV() {
            window.open(
                // eslint-disable-next-line max-len
                `/api/applicant/list/csv?start=${this.startDate}&end=${this.endDate}${this.teamsString}${this.gradesString}&rangeType=hiredAt`,
            )
        },
        getCandidateCountsNumber(key) {
            return Number.isFinite(this.candidateCounts[key]) ? this.candidateCounts[key] : 0
        },
    },
}
</script>

<style module src="./DownloadsSection.css" />
