<template>
    <div :class="$style.reviewProgressContainer">
        <div :class="$style.reviewProgressHeader">
            <div :class="$style.reviewProgressTooltipHeader">Review progress</div>
            <div>
                <a :href="manageReviewProgressUrl">
                    <FontAwesomeIcon :class="$style.externalLinkIcon" :icon="faExternalLinkAlt" />
                </a>
            </div>
            <div :class="$style.closeTooltipIcon" @click="closeTooltip">
                <FontAwesomeIcon :icon="faTimesCircle" />
            </div>
        </div>
        <div :class="$style.progressBarContainer">
            <div>Sift</div>
            <div>
                <SimpleProgressBar
                    :total="reviewProgress.totalReviewCount"
                    :value="reviewProgress.scoredReviewCount"
                    test="me"
                    :showPercentage="false"
                    barBackgroundColour="#FFFFFF"
                    :barWidthInPixels="220"
                />
            </div>
            <div>{{ reviewProgressPercentage }}%</div>
            <div v-if="allReviewsComplete">
                <FontAwesomeIcon :class="$style.allReviewsCompleteIcon" :icon="faCheckCircle" />
            </div>
        </div>
        <div :class="$style.reviewProgressLastAllocated">
            Last allocated: {{ reviewsLastAllocated }}
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faExternalLinkAlt, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import mapIcons from '@/utils/mapIcons'
import SimpleProgressBar from '@/components/SimpleProgressBar/SimpleProgressBar'

export default {
    name: 'ReviewProgressToolTip',
    emits: ['close'],
    components: {
        FontAwesomeIcon,
        faExternalLinkAlt,
        faCheckCircle,
        faTimesCircle,
        SimpleProgressBar,
    },
    props: {
        reviewProgress: Object,
        jobId: Number,
    },
    computed: {
        ...mapIcons({ faCheckCircle, faTimesCircle, faExternalLinkAlt }),
        manageReviewProgressUrl() {
            return `/manage/${this.jobId}/review-progress`
        },
        reviewsLastAllocated() {
            const formattedDate = format(
                new Date(this.reviewProgress.lastBatchSentAt),
                'MM/dd/yy hh:mmaaa',
            )
            return formattedDate.replace(/(am|pm)/i, (match) => match.toUpperCase())
        },
        reviewProgressPercentage() {
            return Math.floor(
                (this.reviewProgress.scoredReviewCount / this.reviewProgress.totalReviewCount) *
                    100,
            )
        },
        allReviewsComplete() {
            return (
                this.reviewProgress.totalReviewCount > 0 &&
                this.reviewProgress.scoredReviewCount === this.reviewProgress.totalReviewCount
            )
        },
    },
    methods: {
        shouldShow(stage) {
            return (
                stage === 'inReviewOrAwaitingInterview' && this.reviewProgress.totalReviewCount > 0
            )
        },
        closeTooltip() {
            this.$emit('close')
        },
    },
}
</script>

<style module src="./ReviewProgressToolTip.css" />
